import React from "react";
import { Divider, Steps } from "antd";
import { FaVolleyballBall } from "react-icons/fa";
import { Link } from "react-scroll";

const Work = () => {
	return (
		<div name="work" className="w-full md:text-gray-300 bg-[#0a192f] pt-40">
			<div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
				<div className="pb-8">
					<p className="text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600">
						Experience
					</p>
					<p className="py-6 text-white">// Check out my recent experiences</p>
				</div>
				<Steps
					className="text-white"
					progressDot
					current={4}
					direction="vertical"
					items={[
						{
							title: (
								<h1 className="text-3xl font-bold inline border-b-2 text-gray-300 border-pink-600">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.hdfcergo.com/">
										HDFC ERGO
									</a>
								</h1>
							),
							description: (
								<div className="text-white mt-2">
									<div className="flex justify-between">
										<h3 className="text-white">07/2022 - Present</h3>{" "}
										<h3>MUMBAI, INDIA</h3>
									</div>
									<p className="text-slate-500 text-xs">
										HDFC ERGO is the third largest private general insurer in
										India and, seventh largest at an overall level
									</p>
									<Steps
										className="text-white mt-4"
										progressDot
										current={0}
										direction="vertical"
										items={[
											{
												title: (
													<h1 className="text-lg text-white ">
														PROJECT:{" "}
														<a
															rel="noreferrer"
															target="_blank"
															className="border-b border-pink-300 "
															href="https://pehchaan.hdfcergo.com/">
															P E H C H A A N
														</a>
													</h1>
												),
												description: (
													<Steps
														className="text-white mt-4"
														progressDot
														current={0}
														direction="vertical"
														items={[
															{
																description: (
																	<p className="text-white">
																		Pehchaan is a KYC verification system, that
																		caters to a customer base exceeding 10
																		million.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Added features like OCR for document data
																		scanning, a human verification dashboard for
																		verification of manual entries, and API
																		integration for partner companies like TVS,
																		and Maruti.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Utilized Signzy APIs to improve verification
																		processes and retrieved data from the CKYC
																		repository
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Customers with data in the CKYC repository
																		were verified instantly, while those
																		submitting manual entries
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		experienced a turnaround time (TAT) of 12
																		hours for approval or rejection.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Our timely project delivery, in line with
																		the IRDAI’s KYC guidelines for insurance
																		from January 1, 2023, prevented disruptions
																		to the company’s daily sales operations.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Technologies: MERN stack (MongoDB,
																		Express.js, React.js, Node.js).
																	</p>
																),
															},
														]}
													/>
												),
											},
										]}
									/>
									<Steps
										className="text-white mt-4"
										progressDot
										current={0}
										direction="vertical"
										items={[
											{
												title: (
													<h1 className="text-lg text-white ">
														PROJECT:{" "}
														<a
															rel="noreferrer"
															target="_blank"
															className="border-b border-pink-300 "
															href="https://chase-vendor.hdfcergo.com/">
															C H A S E
														</a>
													</h1>
												),
												description: (
													<Steps
														className="text-white mt-4"
														progressDot
														current={0}
														direction="vertical"
														items={[
															{
																description: (
																	<p className="text-white">
																		Chase is an in-house application created for
																		managing and investigating fraud claim
																		cases.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Developed two separate applications: one for
																		internal investigators across various
																		hierarchies and another for vendors to
																		gather and input data from incident sites.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Engineered logic to facilitate the seamless
																		progression of claim cases through different
																		investigative stages, with automatic
																		adjustments in case hierarchy and invoice
																		processing based on claim amounts and
																		investigator inputs.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Achieved a 30% reduction in costs and
																		enhanced investigation accuracy by 55%.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Technologies: PERN stack (PostgreSQL,
																		Express.js, React.js, Node.js).
																	</p>
																),
															},
														]}
													/>
												),
											},
										]}
									/>
								</div>
							),
						},
						{
							title: (
								<h1 className="text-3xl font-bold inline border-b-2 text-gray-300 border-pink-600">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://prosofos.com/">
										PROSOFOS
									</a>
								</h1>
							),
							description: (
								<div className="text-white mt-2">
									<div className="flex justify-between">
										<h3 className="text-white">02/2022 - 06/2022</h3>{" "}
										<h3>Contractual - part time - remote</h3>
									</div>
									<p className="text-slate-500 text-xs">
										It is a service based company, helping people create their
										websites
									</p>
									<Steps
										className="text-white mt-4"
										progressDot
										current={0}
										direction="vertical"
										items={[
											{
												description: (
													<p className="text-white">
														Led frontend development using React.js and Redux
														for various projects, Spearheaded the design and
														implementation of interactive and user-friendly
														interfaces, resulting in a 30% increase in user
														engagement.
													</p>
												),
											},
											{
												description: (
													<p className="text-white">
														Optimized code for scalability and maintainability
														by Refactoring existing codebase to enhance
														readability and efficiency, minimized overall bundle
														size, and eased future updates.
													</p>
												),
											},
										]}
									/>
								</div>
							),
						},
						{
							title: (
								<h1 className="text-3xl font-bold inline border-b-2 text-gray-300 border-pink-600">
									<a
										rel="noreferrer"
										target="_blank"
										href="http://lirctek.com/">
										Lirctek
									</a>
								</h1>
							),
							description: (
								<div className="text-white mt-2">
									<div className="flex justify-between">
										<h3 className="text-white">05/2021 - 02/2022</h3>{" "}
										<h3>BANGLORE, INDIA</h3>
									</div>
									<p className="text-slate-500 text-xs">
										Company main agenda is to automate day to day trucking
										operation, by creating Trucking system.
									</p>

									<Steps
										className="text-white mt-4"
										progressDot
										current={0}
										direction="vertical"
										items={[
											{
												title: (
													<h1 className="text-lg text-white ">
														PROJECT:{" "}
														<a
															rel="noreferrer"
															target="_blank"
															className="border-b border-pink-300 "
															href="http://www.etruckingsoft.com/">
															E-T R U C K I N G S O F T
														</a>
													</h1>
												),
												description: (
													<Steps
														className="text-white mt-4"
														progressDot
														current={0}
														direction="vertical"
														items={[
															{
																description: (
																	<p className="text-white">
																		E-TruckingSoft is a platform that helps
																		truck owners manage and optimize the
																		utilization of their fleet.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Improved project performance by 80%, and
																		reduced re-rendering issues using React
																		Profiler, Memo, and batch updates.
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Implemented complex logic and features,
																		including interactive forms, maps, tables,
																		and dynamic graphs on the frontend for
																		enhanced data visualization
																	</p>
																),
															},
															{
																description: (
																	<p className="text-white">
																		Technologies: React.js, TypeScript, Node.js,
																		Express.js, MySQL, Ant Design, Azure.
																	</p>
																),
															},
														]}
													/>
												),
											},
										]}
									/>
								</div>
							),
						},
						{
							title: (
								<h1 className="text-3xl font-bold inline border-b-2 text-gray-300 border-pink-600">
									FREELANCE
								</h1>
							),
							description: (
								<div className="text-white mt-2">
									<div className="flex justify-between">
										<h3 className="text-white">10/2020 - 05/2021</h3>{" "}
									</div>
									<Steps
										className="text-white mt-4"
										progressDot
										current={0}
										direction="vertical"
										items={[
											{
												description: (
													<p className="text-white">
														Developed a full-stack e-commerce platform from the
														ground up, including both the admin dashboard and
														the customer-facing website, and deployed it on AWS
														(EC2, S3).
													</p>
												),
											},
											{
												description: (
													<p className="text-white">
														Successfully delivered portfolio and business
														websites for clients on Upwork, increasing their
														sales and customer engagement.
													</p>
												),
											},
											{
												description: (
													<p className="text-white">
														Technologies: MERN stack (MongoDB, Express.js,
														React.js, Node.js).
													</p>
												),
											},
										]}
									/>
								</div>
							),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default Work;
