import React from 'react';
import Resume from "../assets/LAIQUE-Resume.pdf";
const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
          <div className='sm:text-right text-4xl font-bold'>
            <p>Hi, welcome to my corner of the internet.</p>
          </div>
          <div>
            <p>
              I'm a passionate Full stack software developer based in Mumbai,
              where I spend my time prompting chatgpt to solve my bugs.
              My journey in the world of coding started october 2020 as a freelance developer.
              My corporate journey commenced in May 2021 when I became a part of my first office.
              Since then, I've had the privilege of working as a developer.
              These experiences have not only shaped my skills but have also fueled
              my unwavering curiosity for solving critical problems with an optimized solution.
              When I'm not developing, you can often find me playing football.
              If you have any questions or just want to connect, don't hesitate to reach out through
              <a rel="noreferrer" target='_blank' href="https://www.linkedin.com/in/laique-mirza-55166a1b9/"
                className='!text-blue-300'
              > linkedin
              </a>.
              Thank you for stopping by!
{/* Thanks, I knew though 🥱 */}
            </p>
            <a className="nav-link " href={Resume} download>
              <button className='text-white group border-2 px-4 py-2 my-4 flex items-center hover:bg-pink-600 hover:border-pink-600'>
                Download cv 📥
              </button>
            </a>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default About;
