import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import Antd from '../assets/ant-design.png';
import Mui from '../assets/material-ui.png';

import Express from '../assets/express.png';
import Postgres from '../assets/postgres.png';
    import Redux from '../assets/redux.png';
import RestApi from '../assets/rest-api.png';
import Typescript from '../assets/typescript.png';
import Cplusplus from '../assets/c++.png';
// import Typescript from '../assets/typescript.png';
// import Typescript from '../assets/typescript.png';
// import Typescript from '../assets/typescript.png';
// import Typescript from '../assets/typescript.png';

import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import FireBase from '../assets/firebase.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';

const Skills = () => {
    return (
			<div name="skills" className="w-full bg-[#0a192f] text-gray-300 pt-40">
				{/* Container */}
				<div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
					<div>
						<p className="text-4xl font-bold inline border-b-4 border-pink-600 ">
							Skills
						</p>
						<p className="pt-4">Frontend technologies</p>
					</div>

					<div className="w-full grid grid-cols-2 sm:grid-cols-10 gap-4 text-center py-4">
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={ReactImg} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">React</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Redux} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Redux</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Antd} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Antd</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Mui} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Mui</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Tailwind} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">TailW</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={HTML} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">HTML</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={CSS} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">CSS</p>
						</div>
					</div>

					<p className="">Backend technologies</p>

					<div className="w-full grid grid-cols-2 sm:grid-cols-10 gap-4 text-center py-4">
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Node} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Node</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Express} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Express</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={RestApi} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">REST</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Mongo} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Mongo</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Postgres} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">PgSql</p>
						</div>
					</div>
					<p className="">Languages</p>

					<div className="w-full grid grid-cols-2 sm:grid-cols-10 gap-4 text-center py-4">
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={JavaScript} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Javascript</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Typescript} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">Typescript</p>
						</div>
						<div className="shadow-md shadow-[#040c16] p-4 hover:scale-110 duration-500">
							<img className="w-20 mx-auto" src={Cplusplus} alt="HTML icon" />
							<p className="text-xs text-center mt-2 ">C++</p>
						</div>
					</div>
				</div>
			</div>
		);
};

export default Skills;


    
    // GIT (2.9yrs, 9/10)
    // NPM (2.9yrs, 9/10)
    // DIGITAL (2.9yrs, 9/10) OCEAN
    // AWS (2.9yrs, 9/10)
    // DSA (2.9yrs, 9/10)
    // OOPS (2.9yrs, 9/10)
